<template>
  <v-container fluid>
    <v-card class="mx-auto" :loading="loading">
      <v-card-title>Interview Questions </v-card-title>
      <div class="text-right" style="margin-top: -50px; margin-right: 15px">
        <v-btn
          color="secondary"
          to="/add-interview-question"
          @click="onAddButtonClick()"
          >Add Interviews</v-btn
        >
      </div>
      <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Date</th>
                <th class="text-left">Title</th>
                <th class="text-left">TIme Duration</th>
                <th class="text-left">Welcome Note</th>
                <th class="text-left">Goodbye Note</th>
                <th class="text-left">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in questions" :key="i">
                <td>{{ item.created_at }}</td>
                <td>{{ item.title }}</td>
                <td>{{ item.time_duration }}</td>
                <td>{{ item.welcome_note }}</td>
                <td>{{ item.goodbye_note }}</td>
                <td>
                  <v-btn density="default" @click="onDuplicateButtonClick(item)"
                    >Duplicate</v-btn
                  >
                </td>
                <td>
                  <v-btn density="default" @click="onViewButtonClick(item)"
                    >View</v-btn
                  >
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
    <v-dialog v-model="interviewQuestionPopUp" max-width="600px">
      <v-card>
        <v-card-text class="pa-3 py-4">
          <template>
            <div xs12 style="width: 100%">
              <h3 style="text-align: center; margin-bottom: 20px">
                Interview Questions
              </h3>
              <ul>
                <li
                  style="margin-bottom: 15px"
                  v-for="item in this.selectedInterview"
                  :key="item"
                >
                  {{ item.title }}
                  <!-- <div v-for="item in this.selectedInterview" :key="item"> -->
                  <ul v-if="item.question_type === 'mcq'">
                    <li
                      style="margin-bottom: 15px"
                      v-for="answer in item.possibleAnswers"
                      :key="answer"
                    >
                      {{ answer }}
                    </li>
                  </ul>
                  <div
                    v-if="item.question_type === 'code'"
                    v-html="item.code_input"
                  ></div>
                </li>
              </ul>
            </div>
          </template>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import moment from "moment";
import { mapMutations, mapState } from "vuex";
// import { mapMutations } from "vuex";
export default {
  name: "setting-interview-questions",
  data() {
    return {
      questions: [],
      interviewQuestionPopUp: false,
      count: 0,
      loading: true,
      selectedInterview: [],
    };
  },
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    ...mapMutations(["SET_INTERVIEW_QUESTIONS_LIST"]),
    async fetchList() {
      try {
        this.loading = true;
        const resp = await this.axios.get(
          `/interview?w=interviewQuestions&desc=created_at&company_id=${this.user.company_id}`
        );
        this.questions = resp.data.data.map((item) => {
          return {
            company_id: item.company_id,
            created_at: moment(item.created_at).format("DD MMMM, YYYY"),
            goodbye_note: item.goodbye_note,
            intro_video: item.intro_video,
            interview_questions: this.getFormattedInterviewQuestions(
              item.interview_questions
            ),
            time_duration: item.time_duration,
            title: item.title,
            updated_at: item.updated_at,
            welcome_note: item.welcome_note,
            _id: item._id,
          };
        });
        console.log(this.questions);
        this.count = resp.data.count;
        const { data } = resp.data;

        this.SET_INTERVIEW_QUESTIONS_LIST(data);
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
    getFormattedInterviewQuestions(interviewQuestions) {
      return interviewQuestions.map((question) => {
        return {
          order_no: question.order_no,
          question_type: question.question_type,
          reading_time: question.reading_time,
          screen_share: question.screen_share === "true" ? true : false,
          time_duration: question.time_duration,
          title:
            question.question_type === "code"
              ? question.title.split(/,(.*)/s)[0]
              : question.title,
          code_input:
            question.question_type === "code"
              ? question.title.split(/,(.*)/s)[1]
              : question.title,
          possibleAnswers:
            question.question_type === "mcq" ? question.answers : [],
        };
      });
    },
    onDuplicateButtonClick(question) {
      localStorage.setItem("questionId", question._id);
      this.$router.push(`/add-interview-question`);
    },
    onViewButtonClick(question) {
      let matchedInterview = this.questions.filter(
        (interview) => interview._id === question._id
      );
      this.selectedInterview = matchedInterview[0].interview_questions;
      console.log(
        "this.selectedInterview .interview_questions",
        this.selectedInterview
      );
      this.interviewQuestionPopUp = true;
    },
    onAddButtonClick() {
      localStorage.removeItem("questionId");
    },
  },
  created() {
    this.fetchList();
  },
};
</script>

<style lang="scss" scoped></style>
